import React from "react"


export interface MenuContextType {
    current: number[]
    setCurrent: (idx: number[]) => void
}

export const MenuContext = React.createContext<MenuContextType>({
    current: [],
    setCurrent(idx) {
        throw new Error("unimplement")
    },
})
