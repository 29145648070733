
import { PropsWithChildren, useContext, useEffect, useRef, useState } from "react"
import "./HomePage.scss"
import Screen from "./components/Screen"
import Button from "./components/Button"
import Flex from "../../components/Flex"
import { spacing } from "./styles"
import { FormContext, FormContextType, FormItemContext } from "../../contexts/FormContext"
import datasource from "../../datasource"
import useNotification from "./components/useNotification"
import leazyAiCrmApi, { InquiriesRequest } from "../../api/leazyAiCrm.api"
import MobileIcon from "../../components/MobileIcon"
import { FormItemType } from "../../desktop/pages/HomePage"
import regex from "../../regex"

const {
    title,
    subTitle,
    contactFormSubtitle,
    contactFormTitle,
    contactUsBtnLabel
} = datasource["home"]

export default () => {

    const scrollToContactUsForm = () => {
        document.scrollingElement?.scrollTo({top: window.innerHeight, behavior: "smooth"})
    }

    const form = useFormInstance()
    const [disabled, setDisabled] = useState<boolean>(() => false)
    const {success, error, render: notificationRender} = useNotification()
    const [loading, setLoading] = useState<boolean>(() => false);

    useEffect(() => {
        setDisabled(form.finish?false:true)
    }, [form.finish])

    const doSubmit = () => {
        if(!disabled && !loading){
            setLoading(true)
            const request = form.data as InquiriesRequest
            leazyAiCrmApi.inquiries(request).then(() => {
                success("Successfully updated profile")
                form.clear()
            }).catch(() => error(datasource.contactUs.fail)).finally(() => setLoading(false))
        }
    }


    return (
        <div className="mb-home">
            
            <Screen className="mb-title-screen">
                <Flex vertical align="center" gap={40} className="mb-title-box">
                    <Flex vertical gap={16}>
                        <Flex vertical>
                            <div className="mb-title__h1">{title[0]}</div>
                            <div className="mb-title__h1 title__h1_gradient">{title[1]}</div>
                        </Flex>
                        <div className="mb-title__h2">{subTitle}</div>
                    </Flex>
                    <Button onClick={scrollToContactUsForm} className="mb-title__contactus-btn">
                        <Flex gap={spacing("md")} reposive={false}>
                            {contactUsBtnLabel}
                            <MobileIcon.ArrowUp width={20} height={20}></MobileIcon.ArrowUp>
                        </Flex>
                    </Button>
                </Flex>
            </Screen>

            <Screen className="mb-contactus-screen">
                <Flex vertical className="mb-contactus-box" gap={spacing("4xl")}>
                    <Flex className="mb-contactus__header" vertical gap={16}>
                        <div className="mb-contactus__header__title">{contactFormTitle}</div>
                        <div>
                            <div className="mb-contactus__header__subTitle">{contactFormSubtitle[0]}</div>
                            <div className="mb-contactus__header__subTitle">{contactFormSubtitle[1]}</div>
                        </div>
                    </Flex>
                    <Form form={form}>
                        <Flex vertical gap={spacing("4xl")}>
                            <Flex vertical gap={spacing("2xl")}>
                                <FormItem 
                                    name="name" 
                                    label="Name" 
                                    required
                                    rules={[
                                        {accept: (value) => !!value && "" != value, msg: "", show: false}
                                    ]}
                                >
                                    <Input />
                                </FormItem>
                                <FormItem 
                                    name="subject" 
                                    label="Subject" 
                                    required
                                    rules={[
                                        {accept: (value) => !!value && "" != value, msg: "", show: false}
                                    ]}
                                >
                                    <Input/>
                                </FormItem>
                                <FormItem 
                                    name="email" 
                                    label="Email" 
                                    required
                                    rules={[
                                        {accept: (value) => !value || regex.email.test(value), msg: "Please enter the correct email address", show: true},
                                        {accept: (value) => !!value && "" != value, msg: "", show: false}
                                    ]}
                                >
                                    <Input/>
                                </FormItem>
                                <FormItem name="phone" label="Phone" >
                                    <Input/>
                                </FormItem>
                                <FormItem name="address" label="Address" >
                                    <Input/>
                                </FormItem>
                                <div>
                                    <FormItem name="message" label="Message" >
                                        <Textarea></Textarea>
                                    </FormItem>
                                </div>
                            </Flex>
                            <Button disabled={disabled} className="mb-contactus__form__submit" onClick={doSubmit}>
                                <Flex justify="center">Submit</Flex>
                            </Button>
                        </Flex>
                    </Form>
                </Flex>
            </Screen>
            {notificationRender}
        </div>
    )

}

interface FormInstance extends FormContextType {
    finish: boolean
    data: Record<string, any>
}

const useFormInstance = (): FormInstance => {

    const [finish, setFinish] = useState<boolean>(() => false)
    const [data, setData] = useState<Record<string, any>>(() => {});
    const [error, setError] = useState<Record<string, any>>(() => {});


    useEffect(() => {

        if(!error || Object.keys(error).length == 0){
            setFinish(true)
        }else{
            setFinish(false)
        }
    }, [error])

    return {
        data,
        error,
        finish,
        setFieldValue(name: string, value: any){
            setData({...data, [name]: value})
        },
        setFieldError(fieldName, msg) {
            setError((current) => {
                const _error = {...current, [fieldName]: msg};
                return _error
            })
        },
        setFieldSuccess(fieldName) {
            setError((current) => {
                const _error = {...current}
                delete _error[fieldName]
                return _error;
            })
        },
        clear() {
            setData({})
        },
    }
}

const Form = (props: PropsWithChildren<{form: FormInstance}>) => {

    const {
        form
    } = props

    return (
        <FormContext.Provider value={form}>
            <div>
                {props.children}
            </div>
        </FormContext.Provider>
        
    )
}


const FormItem = (props: PropsWithChildren<FormItemType>) => {

    const {setFieldValue, setFieldError, setFieldSuccess, data = {}, error = {}} = useContext(FormContext)

    const _setFieldValue = (value: any) => {
        if(props.rules){
            const rule = props.rules.find(rule => !rule.accept(value))
            if(rule){
                setFieldError(props.name, {msg: rule.msg, show: rule.show})
            }else{
                setFieldSuccess(props.name)
            }
        }else{
            setFieldSuccess(props.name)
        }
        setFieldValue(props.name, value)
    }

    return (
        <FormItemContext.Provider value={{name: props.name, setFieldValue: _setFieldValue, fieldValue: data[props.name], fieldError: error[props.name]}}>
            <Flex vertical gap={spacing("xs")}>
                <div className={["mb-contactus__form__item__label", error[props.name]?.['show']?"mb-contactus__form__item__label_error":""].join(" ")}>{props.label}{props.required?" * ":""}</div>
                <div className="mb-contactus__form__item__content">
                    {props.children}
                </div>
                <div className="mb-contactus__form__item__error">{error?.[props.name]?.["msg"]??undefined}</div>
            </Flex>
        </FormItemContext.Provider>
    )
}


const Input = (props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {

    // const {setFieldValue} = useContext(FormContext)
    const {setFieldValue, fieldValue = "", fieldError} = useContext(FormItemContext)
    const [tmp, setTmp] = useState<string>(() => fieldValue)
    const [inputing, setInputing] = useState<boolean>()

    useEffect(() => {
        setFieldValue(undefined)
    }, [])

    useEffect(() => {
        setTmp(fieldValue)
    }, [fieldValue])

    const startChange = () => {
        setInputing(true)
    }

    const submitChange = () => {
        setInputing(false)
        setFieldValue(tmp)
    }

    const onChange = (value: string) => {
        setTmp(value)
        if(!inputing){
            setFieldValue(tmp)
        }
    }

    return (
        <input 
            className={["mb-contactus__form__input", (fieldError?.['show'])?"mb-contactus__form__input_error":""].join(" ")}
            {...props}
            onFocus={() => startChange()}
            onBlur={e => submitChange()}
            onChange={e => onChange(e.target.value)}
            value={tmp}
        ></input>
    )
}

const Textarea = (props: React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>) => {

    const {setFieldValue, fieldValue = "", fieldError = {msg: ""}} = useContext(FormItemContext)
    const [tmp, setTmp] = useState<string>(() => fieldValue)
    const [inputing, setInputing] = useState<boolean>()

    useEffect(() => {
        setFieldValue(undefined)
    }, [])

    useEffect(() => {
        setTmp(fieldValue)
    }, [fieldValue])

    const startChange = () => {
        setInputing(true)
    }

    const submitChange = () => {
        setInputing(false)
        setFieldValue(tmp)
    }

    const onChange = (value: string) => {
        setTmp(value)
        if(!inputing){
            setFieldValue(tmp)
        }
    }

    return (
        <textarea 
            className={["mb-contactus__form__textarea", fieldError?.['show']?"mb-contactus__form__textarea_error":""].join(" ")}
            {...props}
            onFocus={() => startChange()}
            onBlur={e => submitChange()}
            onChange={e => onChange(e.target.value)}
            value={tmp}
        ></textarea>
    )
}